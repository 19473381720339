import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      statData: 0,
      list: [],
      isLoading: false,
      isLoading1: false,
      time: ["", ""],
      total: 0,
      condition: {
        page: 1,
        limit: 100,
        ref_code: "",
        sortField: "user_level",
        parent_ref_code: ""
      },
      chainList: []
    };
  },
  methods: {
    async getDataList() {
      this.isLoading = true;
      let rs = await this.$http.post("/user/list", {
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getDataList();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getDataList();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getDataList();
    }
  },
  async mounted() {}
};