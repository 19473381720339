import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      statData: 0,
      list: [],
      isLoading: false,
      isLoading1: false,
      time: ["", ""],
      total: 0,
      total_amount: 0,
      condition: {
        page: 1,
        limit: 100,
        status: "",
        user_id: ""
      },
      chainList: []
    };
  },
  methods: {
    async getDataList() {
      this.isLoading = true;
      let rs = await this.$http.post("/recharge/list", {
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.data.items;
        this.total = rs.data.data.total;
        this.total_amount = rs.data.total_amount;
      }
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getDataList();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getDataList();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getDataList();
    }
  },
  async mounted() {
    this.getDataList();
  }
};