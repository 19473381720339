var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "search-box btns"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 邀请码 "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.condition.ref_code,
      expression: "condition.ref_code"
    }],
    domProps: {
      "value": _vm.condition.ref_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.condition, "ref_code", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 上级邀请码 "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.condition.parent_ref_code,
      expression: "condition.parent_ref_code"
    }],
    domProps: {
      "value": _vm.condition.parent_ref_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.condition, "parent_ref_code", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 查询数量 "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.condition.limit,
      expression: "condition.limit"
    }],
    domProps: {
      "value": _vm.condition.limit
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.condition, "limit", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 排序字段 "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.condition.sortField,
      expression: "condition.sortField"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.condition, "sortField", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "user_level"
    }
  }, [_vm._v("等级")]), _c('option', {
    attrs: {
      "value": "coin"
    }
  }, [_vm._v("HID")]), _c('option', {
    attrs: {
      "value": "spins"
    }
  }, [_vm._v("SPINS")]), _c('option', {
    attrs: {
      "value": "ref_count"
    }
  }, [_vm._v("邀请数")])])]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("查询中...")] : [_vm._v("查询")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.username) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.firstname + " " + item.lastname) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.ref_code) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.user_level) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.coin) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.spins) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.ref_count) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.is_system == 1 ? '系统' : '客户') + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.is_viewstat == 1 ? '合作伙伴' : '普通用户') + " ")])]);
  })], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" 用户ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 用户 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 昵称 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 邀请码 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 等级 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" HID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" SPINS ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 邀请用户数 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 类型 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 用户类型 ")])]);
}];
export { render, staticRenderFns };